<template>
  <div v-if="filterForm">
    <div class="field">
      <label for="filterBy">Filtrar por</label>
      <select
        id="filterBy"
        class="tui tuim ui search dropdown"
        placeholder="Escolha o tipo de dado"
        v-model="filterBy"
        @change="clearQueryField">
        <option value>Escolha o tipo de dado</option>
        <option
          v-for="(item, index) in filters"
          :key="index"
          :value="item.key"
          v-text="item.text"
        />
      </select>
    </div>

    <div class="field" v-show="filterBy === 'status'">
      <select
        name="filterDonor[status]"
        class="tui tuim ui search dropdown"
        v-model="donationStatus">
        <option value>Status</option>
        <option
          v-for="(item, index) in status"
          :key="index"
          :value="item.key"
          v-text="item.text"
        />
      </select>
    </div>

    <div class="field" v-show="filterBy === 'paymentMethod'">
      <select
        name="filterDonor[status]"
        class="tui tuim ui search dropdown"
        v-model="paymentMethod">
        <option value>Forma de pagamento</option>
        <option
          v-for="(item, index) in paymentMethods"
          :key="index"
          :value="item.key"
          v-text="item.text"
        />
      </select>
    </div>

    <div class="field" v-show="filterBy === 'name'">
      <input type="text" name="filterDonor[name]" placeholder="Nome" v-model="filterForm.fieldOne">
    </div>

    <div class="field" v-show="filterBy === 'donationValue'"> <!-- mudar aqui -->
      <div class="field">
        <select
          name="filterDonor[operatorQty]"
          class="tui tuim ui search dropdown"
          placeholder="Escolha o tipo de dado"
          v-model="operatorQty">
          <option value>Escolha a condicional</option>
          <option
            v-for="(item, index) in operators"
            :key="index"
            :value="item.key"
            v-text="item.text"
          />
        </select>
      </div>
      <div class="field" v-if="filterBy === 'donationValue'">
        <money name="filterDonor[qty]" class="donation-qty" placeholder="Quantidade" :disabled="!operatorQty" v-model="filterForm.fieldOne" v-bind="money" />
      </div>
    </div>

    <div v-show="filterBy === 'period'">
      <div class="field" :class="{ 'error' : errorDate(filterForm.fieldOne) }">
        <the-mask
          v-if="filterBy === 'period'"
          :mask="['##/##/####']"
          v-model="filterForm.fieldOne"
          :masked="true"
          placeholder="Período Inicial" />
      </div>
      <div class="field" :class="{ 'error' : errorDate(filterForm.fieldTwo) }">
        <the-mask
          v-if="filterBy === 'period'"
          :mask="['##/##/####']"
          v-model="filterForm.fieldTwo"
          :masked="true"
          placeholder="Período Final" />
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { checkDate } from '@/helpers'

export default {
  name: 'TDonorsFilters',
  data () {
    return {
      filters: [
        { key: 'status', text: 'Status da doação' },
        { key: 'paymentMethod', text: 'Método de pagamento' },
        { key: 'name', text: 'Nome do doador' },
        { key: 'donationValue', text: 'Valor da doação' },
        { key: 'period', text: 'Período' },
      ],
      status: [
        { key: 'active', text: 'Ativo' },
        { key: 'inactive', text: 'Inativo' },
        { key: 'finalized', text: 'Finalizado' }
      ],
      paymentMethods: [
        { key: 'bank_slip', text: 'Boleto' },
        { key: 'credit_card', text: 'Cartão de Crédito' },
        { key: 'debit_account', text: 'Débito' },
        { key: 'pix', text: 'Pix' },
      ],
      operators: [
        { key: '=', text: 'Igual a' },
        { key: '>', text: 'Superior a' },
        { key: '<', text: 'Inferior a' },
      ],
      filterBy: null,
      operatorQty: null,
      donationStatus: null,
      paymentMethod: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: true
      }
    }
  },

  computed: {
    ...mapState({
      filterForm: ({ donations }) => donations.filterForm
    })
  },

  watch: {
    operatorQty (value) {
      this.filterForm.equalTo = this.operators.find( operator => operator.key === value)
    },

    filterBy (value) {
      this.filterForm.filterBy = this.filters.find( filter => filter.key === value )
    },

    donationStatus (value) {
      this.filterForm.fieldOne = this.status.find( status => status.key === value )
    },

    paymentMethod (value) {
      this.filterForm.fieldOne = this.paymentMethods.find( paymentMethod => paymentMethod.key === value )
    }
  },

  methods: {
    bindPlugins () {
      $('.ui.search.dropdown').dropdown()
    },

    errorDate (value) {
      if (this.filterBy && this.filterBy === 'period') {
        return checkDate(value)
      }
      return false
    },

    clearQueryField () {
      if (this.filterForm.fieldOne) {
        this.filterForm.fieldOne = ''
      }
    }
  },

  mounted () {
    this.bindPlugins()
  }

}
</script>

<style lang="scss" scoped>
  .donation-qty {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }
</style>
